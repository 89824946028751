import React from "react"
import cyberimage from "../../assets/images/banners/service-images/cyber.jpg"

const CyberSecurity = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div
          className="about-banner-wrap banner-space bg-img cyber"
          data-bg="assets/images/banners/service-banners/cyber.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="about-banner-content text-center">
                  <h1 className="mb-15 text-white">Cyber Security</h1>
                  <h5 className="font-weight--normal text-white">
                    Customer Relationship Management (CRM) is a tool for
                    managing customer relationships. In today's world, when
                    consumers are a company's most precious asset and must be
                    prioritized above all.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--=========== About Company Area Start ==========-->
            <!--=========== Service Projects Wrapper Start =============--> */}
        <div className="service-projects-wrapper section-space--ptb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h6 className="section-sub-title">Our IT Service</h6>
                </div>
              </div>
            </div>
            <div className="">
              <div className="">
                <div className="">
                  <div className="row">
                    <div className="col-lg-6 col-xl-6 order-2 order-lg-1 v1-d-f">
                      <div className="v1-dt-box-0-text">
                        <h2 className="heading">Cyber <span className="text-color-primary">Security Service</span></h2>
                        Starting from a set of identification data items, such
                        as brands, names, IPs, DNS domains, etc., data is
                        obtained, correlated and transformed into information,
                        which is then evaluated and transformed into
                        intelligence. This intelligence is the actionable
                        asset you require to take your digital security to an
                        advanced level. We can provide a much broader
                        understanding of critical infrastructure, computer
                        security, and security policy to your firm since we're
                        always on the pulse of current cybersecurity
                        developments. Allow us to serve as your sole security
                        resource or to supplement your existing IT
                        capabilities by approaching your environment
                        objectively and resolving your most difficult security
                        concerns.
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-6 order-1 order-lg-2">
                      <div className="v1-dt-box-0-img">
                        <img className="img-fluid" src={cyberimage} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--=========== Service Projects Wrapper End =============-->

            <!--=========== About Company Area End ==========-->
            
            <!--========= More Services Start ===========--> */}
        <div className="about-delivering-wrapper bg-gray section-space--pb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h2 className="heading">What <span className="text-color-primary">We Offer</span></h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Security Incident Response</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Forensic Response </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Business Continuity Planning </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">
                        {" "}
                        Security Orchestration and Automation{" "}
                      </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">
                        {" "}
                        Database and Infrastructure Security{" "}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Network Security </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Endpoint Device Security</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">
                        Recovery and Planning for a Disaster
                      </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Cloud Security</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Digital Identity </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--========= More Services End ===========--> */}
      </div>
    </div>
  )
}
export default CyberSecurity
