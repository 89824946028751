import * as React from "react"
import Layout from "../components/layout"
import CyberSecurity from "../components/Services/CyberSecurity"






const cyber = () => {
  return (
  <Layout>
   <CyberSecurity/>
  </Layout>
)
};
export default cyber;
